import { Layout } from "components/site/Layout";
import Link from "next/link";

/**
 * @url /404
 */
export default function NotFoundPage() {
  return (
    <Layout pageName="Page not found">
      <div className="sm:flex sm:items-start">
        <div className="mb-8 overflow-hidden bg-white shadow sm:mb-0 sm:flex-1 sm:rounded-lg">
          <div className="px-8 py-8">
            <div className="mb-4 flex justify-center">
              <p className="text-gray-900">
                Sorry, that page can&apos;t be found.
              </p>
            </div>
            <div className="flex justify-center">
              <p className="text-gray-900">
                <Link
                  href="/"
                  className="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline focus:outline-none"
                >
                  Click here
                </Link>{" "}
                to return to the home page.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
